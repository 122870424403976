import { JwtUtils } from '@auspost/wfv-test-utils';

const fetchData = url => {
  return fetch(url)
    .then(data => data.text())
    .then(data => (data ? JSON.parse(data) : {}));
};

window.__AUSPOST__ = {
  createAuthWebService: () => {
    const apiBaseUrl = `https://api.pvoi.identityservice-pdev.npe.auspost.com.au/mock`;
    return {
      initialise: () => {
        return fetchData(`${apiBaseUrl}/uiim/initialise`);
      },
      login: () => {
        return fetchData(`${apiBaseUrl}/uiim/login`).then(() =>
          window.location.replace('https://pvoi.identityservice-pdev.npe.auspost.com.au/stubpage')
        );
      },
      logout: () => {
        return fetchData(`${apiBaseUrl}/uiim/logout`).then(() =>
          window.location.replace('https://pvoi.identityservice-pdev.npe.auspost.com.au/stubpage')
        );
      },
      isAuthenticated: () => {
        return fetchData(`${apiBaseUrl}/uiim/isAuthenticated`);
      },
      getAccessTokenSilently: () => {
        const jwtSubject = sessionStorage.getItem('mock-jwt-subject');
        const claims = {
          sub: jwtSubject
        };
        const jwt = JwtUtils.createSignedJwt(claims);
        return Promise.resolve(jwt);
      }
    };
  }
};
